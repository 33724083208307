<template>
  <router-view></router-view>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

// Vue.use(VueFormGenerator)

export default {
  name: "Index",

  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Материали" }]);
  },
  methods: {}
};
</script>
